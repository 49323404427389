import Collections from "../Collections"
import Contact from "../Contact"
import Header from "../Header"
import HighPressur from "./HighPressur"
import PremumProducts from "./PremuimProducts"

const Home = () =>{
    return<>
      <Header />
      <Collections />
      <HighPressur />
      <PremumProducts />
      <Contact />
    </>
}
export default Home
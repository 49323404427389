import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './style.css'
import { Link } from 'react-router-dom';
import HomeService from '../../services/HomeService';

const Collections = () => {
    const [description, setDescription] = useState('')
    const [collection, setCollection] = useState([])
    const homeService = new HomeService()

    useEffect(()=>{
        homeService?.getCollection().then(res=>{
        if(res?.status === 200){
          setCollection(res?.data?.data?.Collection_images)
          setDescription(res?.data?.data?.description)
        }
      })
    },[])

    return <div id='collection' className="collection container">
        <Row>
            <Col md={6} xs={12} className='p-5' data-aos="fade-right" ata-aos-duration="800">
                <h1 className='primary mb-0'>Hasan AL Nasser</h1>
                <h1 className='primary'>Collections</h1>
                <p className='m-0' dangerouslySetInnerHTML={{ __html: description }}></p>
                <Link to='/about-us' className='about mt-5'>About Us</Link>
            </Col>
            <Col md={2} xs={12}></Col>
            <Col md={4} xs={12} data-aos="fade-left" ata-aos-duration="800">
                <Row>
                    {collection?.map(img=>{
                        return <Col key={img?.id} xs={6} className='mb-3'>
                        <div>
                            <img src={img?.url} alt={img?.id} style={{
                                width: '100%',
                                height: 'auto'
                            }} />
                        </div>
                    </Col>
                    })}
                </Row>
            </Col>
        </Row>
    </div>
}
export default Collections;
import { useEffect, useState } from "react"
import NavBar from "../Navbar"
import { Card, Col, Row } from "react-bootstrap"
import { Link, useLocation, useNavigate } from "react-router-dom"
import ProductsService from "../../services/ProductsService"

const Products = () =>{
    const [products, setProducts] = useState([])
    const [hasData, setHasData] = useState(null)
    const [loading, setLoading] = useState(false)
    const productsService = new ProductsService()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(()=>{
        let params = {
            search: location.state
        }
        setLoading(true)
        productsService?.getList({ 
            ...params
        }).then(res=>{
        if(res?.status === 200){
            setProducts(res?.data?.data?.data)
            if(res?.data?.data?.data?.length > 0){
                setHasData(1)
            } else {
                setHasData(0)
            }
        }
        setLoading(false)
      }).catch(()=>setLoading(false))
    },[location])

    return <>
    <NavBar />
    <div className="container PreumiumProducts" style={{marginTop: '10rem'}}>
        {location.state && <h1 className="primary mb-0">SEARCH: <span style={{marginLeft: '1rem', color: '#111'}}>{location.state}</span></h1>}

        <Row>
            {loading && <h3 className="primary" style={{textAlign: 'center', marginTop: '5rem'}}>Loading ...</h3>}
            {(hasData === 1 && !loading) && products?.map(product=>{
                return <Col md={3} xs={6} sm={6} className='mb-3' key={product?.id}>
                    <div onClick={()=> navigate(`/products/${product.id}`, {state:product})} state={product} style={{textDecoration: 'none', height:'100%'}}>
                    <Card className="text-center h-100" style={{border: 'none', boxShadow: '0 0 7px #ebebeb'}}>
                        <div style={{borderRadius: '5px'}} className='p-2 h-100'>
                            <img 
                                src={product.show_product_images[0]?.url} 
                                alt='product' 
                                className="w-100 h-100" 
                            />
                        </div>
                        <h4 className="p-2">{product.title}</h4>
                    </Card>
                    </div>
                </Col>
            })}
            {(hasData === 0 && !loading) && <h3 className="primary" style={{textAlign: 'center', marginTop: '5rem'}}>Sorry! No Products Found...</h3>}
        </Row>
    </div>
    </>
}
export default Products
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import './style.css'
import ProductsService from "../../../services/ProductsService";

const PremumProducts = () =>{
    const [data, setData] = useState([])
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    };

    const productsService = new ProductsService()

    useEffect(()=>{
        productsService?.getList(
            {
                limit: 9,
                offset: 0,
                type: 'premium_products'
            }
        ).then(res=>{
        if(res?.status === 200){
          setData(res?.data?.data?.data)
        }
      })
    },[])

    return <div className="premumProducts container">
        <Row>
            <Col md={8}>
                <h1 className="title primary">premium Products</h1>
            </Col>
            <Col md={4}>
                <p>Explore all Hassan al Naser Products</p>
                <Link to="/preumium-products" className="view-all">View All</Link>
            </Col>
        </Row>
        <Row className="mt-5">
            <Col md={3} className='d-flex mb-4' style={{ flexDirection: 'column', justifyContent: 'flex-end'}}>
                <h3>conceptualization and Design</h3>
            </Col>
            <Col md={9} data-aos="zoom-in" data-aos-duration="800">
                {data?.length > 0 && <div className="slider-container">
                    <Slider {...settings}>
                        {data?.map(product=>{
                            return <Link to={`/products/${product.id}`} key={product.id} className="h-100" state={product}>
                                <img src={product.show_product_images[0]?.url} alt={product.id} className='w-100' />
                            </Link>
                        })}
                    </Slider>
                </div>}
            </Col>
        </Row>
    </div>
}
export default PremumProducts;

import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './style.css'
import ProductsService from '../../../services/ProductsService';
import { useEffect, useState } from 'react';

const HighPressur = () =>{
    const [data, setData] = useState([])
    const productsService = new ProductsService()

    useEffect(()=>{
        productsService?.getList(
            {
                limit: 3,
                offset: 0,
                type: 'high_pressure'
            }
        ).then(res=>{
        if(res?.status === 200){
          setData(res?.data?.data?.data)
        }
      })
    },[])

    return <div className="container">
        <Link to='/high-pressure'>
        <div className='position-relative'>
            <div id="highPressur"  className='highPressur'>
                    <h1>High Pressure Laminate Sheets</h1>
                    <div className='ce'>
                        <Row>
                            {data?.length > 0 && data?.slice(0,3)?.map(res=> {
                                return <Col xs={4} key={res?.id} className='text-center' data-aos="flip-left" data-aos-duration="800">
                                <img src={res?.show_product_images[0].url} alt='1' />
                                {/* <span>{res?.title}</span> */}
                            </Col>
                            })}
                        </Row>
                    </div>
            </div>
        </div>
        </Link>
    </div>
}
export default HighPressur;
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png'
import './style.css'
import { useState } from 'react';

function NavBar() {
  const [search, setSearch] = useState('')
  const navigate = useNavigate()

  return (
    <Navbar collapseOnSelect expand="lg" fixed="top" className="bg-body-tertiary py-2">
      <Container style={{direction: 'rtl'}}>
        <Navbar.Brand>
          <NavLink to="/">
            <img src={logo} alt="Logo" height='50px' />
          </NavLink>
        </Navbar.Brand>
        <form onSubmit={(e)=> {
          e.preventDefault()
          navigate('/products', {state: search})
        }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          direction: 'ltr',
          borderBottom: '1px solid #fff'
        }}>
          <button type='submit' style={{background: 'none', border: 'none'}}>
            <i className='la la-search'></i>
          </button>
          
          <input 
            type='text' 
            placeholder='Search Here'
            value={search}
            onChange={e=> setSearch(e.target?.value)}
            onEnter
          />
        </div>
        </form>
        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="m-auto">
            <NavLink to="/" className='nav-link'>
                الرئيسية<i className="la la-arrow-left"></i>
            </NavLink>
            <NavLink to="/activities" className='nav-link'>
                الأنشطة والفاعليات
            </NavLink>
            <NavLink to="/markets" className='nav-link'>الفروع والأسواق</NavLink>
            <NavLink to="/offers" className='nav-link'>عروض الجمعية</NavLink>
            <NavLink to="/news" className='nav-link'>أخبار الجمعية</NavLink>
            <NavLink to="/contact-us" className='nav-link'>اتصل بنا</NavLink>
          </Nav>
        </Navbar.Collapse> */}
      </Container>
    </Navbar>
  );
}

export default NavBar;
import { Col, Row } from "react-bootstrap";
import logo from '../../assets/logo.png'
import './style.css'
import { useState } from "react";
import HomeService from "../../services/HomeService";
import { toast } from "react-toastify";
// import ContactUsService from "../../services/ContactUsService";
// import { toast } from "react-toastify";


const initialData = {
    f_name: "",
    l_name: "",
    email: "",
    phone: "",
    message: "",
}
const Contact = () => {
    const [formData, setFormData] = useState(initialData)
    const [loading, setLoading] = useState(false)
    const homeService = new HomeService()

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const onSubmit = () => {
        let data = {...formData}
        setLoading(true)
        homeService?.postContact(data).then(res=>{
            if(res?.status === 201){
                toast.success('تم ارسال الرساله بنجاح.')
                setFormData(initialData)
            }
            setLoading(false)
        }).catch(()=> setLoading(false))
    }
    return <div className="container">
                <div className="contact">
                    <Row>
                        <Col md={5} sm={12} className="info">
                            <h4>Contact Us</h4>
                            <div className="mt-5">
                                {/* <img src={logo} alt='logo' className="w-50 mt-5" /> */}
                            </div>
                        </Col>
                        <Col md={7} sm={12} className="form" data-aos="zoom-out-left" data-aos-duration="1000">
                            <div className="back">
                                <h3>Contact Us</h3>
                                <Row>
                                    <Col md={6} className='p-3 mb-2'>
                                        <label>First Name</label>
                                        <input 
                                            type='text'
                                            name='f_name'
                                            value={formData.f_name}
                                            required
                                            onChange={e=> handleChange(e)}
                                        />
                                    </Col>
                                    <Col md={6} className='p-3 mb-2'>
                                        <label>Last Name</label>
                                        <input 
                                            type='text'
                                            name='l_name'
                                            value={formData.l_name}
                                            required
                                            onChange={e=> handleChange(e)}
                                        />
                                    </Col>
                                    <Col md={6} className='p-3 mb-2'>
                                        <label>Email</label>
                                        <input 
                                            type='email'
                                            name='email'
                                            value={formData.email}
                                            required
                                            onChange={e=> handleChange(e)}
                                        />
                                    </Col>
                                    <Col md={6} className='p-3 mb-2'>
                                        <label>Phone</label>
                                        <input 
                                            type='number'
                                            name='phone'
                                            value={formData.phone}
                                            required
                                            onChange={e=> handleChange(e)}
                                        />
                                    </Col>
                                    <Col md={12} className='p-3 mb-2'>
                                        <label>Message</label>
                                        <input 
                                            type='text'
                                            name='message'
                                            value={formData.message}
                                            required
                                            onChange={e=> handleChange(e)}
                                        />
                                    </Col>
                                </Row>
                                <div className="submit">
                                    <button type="button" disabled={loading} style={{backgroung: 'rgb(96,102,107)'}} onClick={()=> onSubmit()}>
                                    Submit
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
    </div>
}
export default Contact;
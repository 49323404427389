import { useEffect, useState } from "react";
import StaticPagesServices from "../../services/StaticPagesService";
import NavBar from "../Navbar";

const AboutUs = () => {
    const [ data, setData] = useState([])
    const staticPagesServices = new StaticPagesServices()

    useEffect(()=>{
        staticPagesServices?.getList({type: 'about'}).then(res=>{
            if(res?.status === 200){
                setData(res?.data?.data)
            }
        })
    },[])

    return<>
    <NavBar />
    <div className="container about" style={{marginTop: '10rem', minHeight: '15rem'}}>
        <h1 className="primary">About Us</h1>
        {data?.map(info=>{
            return <div className="mt-5">
                <h2>{info?.title_en}</h2>
                <p dangerouslySetInnerHTML={{ __html: info?.description_en }}></p>
            </div>
        })}
    </div>
    </>
}
export default AboutUs;
import { useEffect, useState } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import NavBar from "../Navbar";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper/modules"
import 'swiper/css/pagination';
import "./style.css"
import 'swiper/css'

const Product = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const product = {...location?.state}
    const [currentImage, setCurrentImage] = useState({})

    useEffect(()=>{
        if(!location?.state) navigate('/products')
        if(!!product?.show_product_images?.length) setCurrentImage(product?.show_product_images[0])
    },[])
    return <>
        <NavBar />
        <div className="container products" style={{marginTop: '10rem'}}>
            <Row>
                <Col md={5} className='text-center mb-5'>
                    <img src={currentImage?.url} alt='product' className="w-75" />
                    <div className="mt-4">
                    <Swiper
                        modules={[Pagination]}
                        slidesPerView={3}
                        spaceBetween={10}
                        pagination={{
                            clickable: true
                        }}
                        breakpoints={{
                            640: { slidesPerView: 3 },
                            768: { slidesPerView: 3 },
                            1024: { slidesPerView: 3 },
                        }}
                        className="mySwiper"
                    >
                        {product?.show_product_images?.map((img,index)=>{
                            if(img?.id === currentImage?.id){
                                return ''
                            } 
                            return <SwiperSlide key={index}>
                                <img 
                                    src={img?.url} 
                                    alt={index} 
                                    key={index} 
                                    style={{
                                        border: img?.id === currentImage?.id ? '1px solid' : '',
                                    }}
                                    className='w-100 h-100' 
                                    onClick={()=> setCurrentImage(img)}
                                />  
                            </SwiperSlide>
                        })}
                    </Swiper>
                    </div>
                </Col>
                <Col md={7}>
                    <div>
                        <h1>{product?.title}</h1>
                        <Badge 
                            className="mb-4 p-2 bg-blue" 
                            // variant='primary'
                            style={{
                                textTransform: 'capitalize',
                                fontSize: '18px'
                            }}
                        >
                            {product?.type?.replaceAll("_", " ")}
                        </Badge>
                        <p className="text-primary">About Product</p>
                        <p dangerouslySetInnerHTML={{ __html: product?.description }}></p> 
                    </div>
                </Col>
            </Row>
        </div>
    </>
}
export default Product;
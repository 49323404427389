import React from 'react'
import { Link } from 'react-router-dom'

const Error404 = () => {
  return (
    <div style={{marginTop: '7rem'}}>
      <div className='container'>
        <div className='form-input-content text-center'>
              <p style={{
                  fontSize: '180px',
                  margin: '0',
                  fontWeight: '700',
                  fontFamily: 'fantasy',
                }}>404</p>
              <h4>
              PAGE NOT FOUND
              </h4>
              <p>
              THE PAGE YOU ARE LOOKING FOR WAS MOVED, REMOVED,
              RENAMED OR MIGHT NEVER EXISTED.
              </p>
              <div>
                <Link className='btn btn-primary' to='/'>
                Back to Home
                </Link>
              </div>
            </div>
      </div>
    </div>
  )
}

export default Error404

import { useEffect, useState } from "react"
import NavBar from "../Navbar"
import pdf from '../../assets/pdf.png'
import table from '../../assets/table.png'
import { Card, Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import ProductsService from "../../services/ProductsService"
import BannerService from "../../services/BannerService"

const HighPressur = () =>{
    const [viewAll, setViewAll] = useState(false)
    const [products, setProducts] = useState([])
    const productsService = new ProductsService()
    const [pdfF, setPdfF] = useState('')
    const bannerService = new BannerService()

    useEffect(()=>{
      bannerService?.getList().then(res=>{
        if(res?.status === 200){
          let info = res?.data?.data?.filter(c => !!c?.catalog)
          if(info?.length > 0){
            setPdfF(info[0].catalog)
          } 
        }
      })
    },[])

    useEffect(()=>{
        let params = {
            limit: 9,
            offset: 0,
        }
        if(viewAll) params = {}
        productsService?.getList({ 
            ...params,
            type: 'high_pressure' 
        }).then(res=>{
        if(res?.status === 200){
            setProducts(res?.data?.data?.data)
        }
      })
    },[viewAll])

    return <>
    <NavBar />
    <div className="container PreumiumProducts" style={{marginTop: '10rem'}}>
        <h1 className="primary mb-0">HIGH PRESSURE</h1>
        <h1 className="primary">LAMINATE SHEETS</h1>

        <div style={{margin: '5rem 0 3rem'}}>
           <img 
                src={table} 
                alt='table' 
                className="w-100" 
                style={{height: '300px', borderRadius: '30px'}} 
            />
        </div>

        <Row>
            <Col md={12} xs={12} className='mb-3 mt-4'>
                <div className="text-center">
                    <h5 className="primary mb-1">Sheets we provide</h5>
                    <h5>Shop By High Pressure Laminate Sheets</h5>
                </div>
            </Col>
            {products?.length > 0 ? products?.map(prod=>{
                return <Col md={3} xs={6} sm={6} className='mb-3' key={prod?.id}>
                    <Link to={`/products/${prod.id}`} state={prod} style={{textDecoration: 'none', padding:'0 1rem'}}>
                    <Card className="text-center h-100" style={{border: 'none', boxShadow: '0 0 7px #ebebeb'}}>
                        <div style={{borderRadius: '5px'}} className='p-2 h-100'>
                            <img 
                                src={prod.show_product_images[0]?.url} 
                                alt='product' 
                                className="w-100 h-100" 
                            />
                        </div>
                        <h4 className="p-2">{prod.title}</h4>
                    </Card>
                    </Link>
                </Col>
            }) : <h4 className="text-center primary mt-5">No Products Found...</h4>}
        </Row>
        {(!viewAll && products?.length > 0) && <div className="text-center mt-5">
            <button className="viewAll" onClick={()=>setViewAll(true)}>View All</button>
        </div>}

        <div className="banner bg-blue">
            <Row>
                <Col md={4}>
                    <p style={{fontWeight: '600'}}>Download Our Catalog</p>
                    <h3>EXPLORE ALL HASAN AL NASER PRODUCTS</h3>
                </Col>
                <Col md={5}></Col>
                <Col md={3}>
                    <div>
                        <img src={pdf} alt='pdf' width={90} className='d-block mb-4'></img>
                        <a href={pdfF} target="_blank" className="viewAll" rel="noreferrer">Download</a>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
    </>
}
export default HighPressur
import { API_BASE_URL_ENV } from "../common/common";
import BaseService from "./BaseService";
import http from './HttpService'


const apiEndpoint = API_BASE_URL_ENV() + "/home";

export default class HomeService extends BaseService {
    constructor() {
        super(apiEndpoint);
    }

    // Collection
    getCollection() {
        return http.get(`${this.apiEndpoint}/collection`);
    }

    // Barcode
    getBarcode() {
        return http.get(`${this.apiEndpoint}/barcode`);
    }

    // Contact Us
    postContact(data) {
        return http.post(`${this.apiEndpoint}/contactUs`, data);
    }
}

import { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import './style.css'
import BannerService from '../../services/BannerService';

function Header() {
    const [data, setData] = useState([])
    const bannerService = new BannerService()

    useEffect(()=>{
      bannerService?.getList().then(res=>{
        if(res?.status === 200){
          let info = res?.data?.data?.map(da => {
            return {
              ...da,
              tags: da.tags.map(tag=> tag?.name)
            }
          })
          setData(info)
        }
      })
    },[])

  return (
    <div className='container'>
    <Carousel data-bs-theme="dark" controls='false' id='home'>
        {data?.map((item, index)=>{
          return <Carousel.Item key={index}>
            <img 
              src={logo} 
              className='logo'
              alt='logo' 
              style={{
                position: 'absolute',
                width: '17%',
                height: '28%',
                right: '-10px',
                top: '-25px',
                zIndex: '999'
              }} 
            />
            <img
              className="d-block w-100"
              src={item?.image}
              alt="First slide"
            />
            <Carousel.Caption>
              <div data-aos="fade-left">
                <h1>{item?.title || ''}</h1>
                <p className='description'>{item?.description || ''}</p>
                <a rel="noreferrer" href={item.catalog} target='_blank' className='catalog'>Download Catalog</a>
                <div className='tags'>
                {item.tags?.map((tag,ind)=>{
                  return <p key={ind} className='tag'>{tag}</p>
                })}
                </div>
              </div>
              <Link to='/preumium-products' className='explore'>Explore All</Link>
            </Carousel.Caption>
          </Carousel.Item>
        })}
    </Carousel>
    </div>
  );
}

export default Header;